<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }" :label="l('Name')">
                            <a-input v-model="entity.name" style="width: 100%;"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 1 }" :label="l('OptionListDetail.IsQualified')" style="margin-bottom: -20px;">
                            <a-switch v-model="entity.isQualified"></a-switch>
                        </a-form-item>
                    </a-col>
					<a-col :span="24">
						<a-col :span="7">
							
						</a-col>
						<a-col :span="17">
							<p style="color: red;">{{l('SelectTheNonconformance')}}</p>
						</a-col>
					</a-col>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 7 }" :label="l('OptionListDetail.Score')">
                            <a-input-number v-model="entity.score" style="width: 100%;"></a-input-number>
                        </a-form-item>
                    </a-col>
					<a-col :span="24" style="margin-top: -10px;margin-bottom: -10px;">
					    <a-col :span="7">
					        <a-form-item :label="l('issueprogress.forecolor')">
					        </a-form-item>
					    </a-col>
					    <a-col :span="10">
					        <input type="color" style="width: 200px;height: 40px; border: transparent;"
					               v-model="foreColor" @change="colorselect" title="点击拾色器外面关闭拾色器" id="myColor2">
					    </a-col>
					</a-col>
                    <a-col :span="24" style="margin-bottom: -20px;">
                        <a-col :span="7">
                            <a-form-item :label="l('issueprogress.backcolor')">
                            </a-form-item>
                        </a-col>
                        <a-col :span="10">
                            <input type="color" style="width: 200px;height: 40px; border: transparent;"
                                   v-model="backColor" @change="colorselect" title="点击拾色器外面关闭拾色器" id="myColor2">
                        </a-col>
                    </a-col>

                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { OptionListServiceProxy, OptionListDetailEditDto } from '../../../../shared/service-proxies';
    let _this;
    export default {
        name: 'create-or-update-detail',
        mixins: [ModalComponentBase],
        components: {

        },
        data() {
            return {
                zh_CN,
                isLoading:false,
                id: undefined,
                isEdit: false,//是否是编辑
                entity: new OptionListDetailEditDto(),
                isclick: true,
                optionListId:undefined,
				foreColor:"#000000",
                backColor:"#dfdfdf",
            }
        },
        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.OptionListServiceProxy = new OptionListServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if (this.id) {
                this.getData();
            }
        },
        methods: {
            colorselect() {
		        console.log(this.entity.color)
		    },
            getData() {
                this.isLoading = true;
                this.OptionListServiceProxy.getForEdit_Detail(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
					if(res.foreColor==null) {
						_this.foreColor = '#000000';
					} else{
						_this.foreColor = res.foreColor;
					}
                    if(res.backColor==null) {
						_this.backColor = '#dfdfdf';
					} else{
						_this.backColor = res.backColor;
					}
                    console.log(this.backColor);
                })
            },
            save() {
                if (!this.entity.name) {
                    abp.message.info('名称不能为空');
                    return;
                }
                this.entity.optionListId = this.optionListId;
				this.entity.foreColor=this.foreColor;
                this.entity.backColor=this.backColor;
                if (this.isclick) {
                    this.isclick = false;
                    this.OptionListServiceProxy.createOrUpdate_Detail(
                        this.entity
                    ).finally(() => {
                        this.close()
                    }).then(res => {
                        this.isclick = true;
                        this.success(true)
                    })
                }
            },
        },
    }
</script>
<style scoped>
	
</style>