<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" :label="l('Name')">
                            <a-input v-model="entity.name" class="input" style="margin-left: 30px;"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" :label="l('DisplayForm')">
                            <a-radio-group v-model="entity.displayForm" style="text-align: left;width: 100%;">
                                <a-radio :value="'1'" style="margin-left: 30px;">
                                    {{l('PullDown')}}
                                </a-radio>
                                <a-radio :value="'2'" style="margin-left: 100px;">
                                    {{l('Tiled')}}
                                </a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
					<a-col :span="24" style="margin-top: -15px;">
						<a-col :span="7">
							
						</a-col>
						<a-col :span="17">
							<div class="images" v-vuer="{title: false}" id="index">
								<img src="../../../../../public/img/icons/pull_down.png" style="width: 140px;margin-left: 30px;" />
								<img src="../../../../../public/img/icons/tiled.png" style="height: 50px;margin-left: 30px;" />
							</div>
						</a-col>
						<a-col :span="8" style="text-align: center;">
							
						</a-col>
					</a-col>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" :label="l('IsQuickCheck')">
							<div style="text-align: left;width: 100%;">
								<a-switch default-checked v-model="entity.isQuickCheck" style="margin-left: 30px;" />
							</div>
                        </a-form-item>
                    </a-col>
					<a-col :span="24" style="margin-top: -15px;">
						<a-col :span="7">
							
						</a-col>
						<a-col :span="17">
							<img src="../../../../../public/img/icons/Quick_spot_check.gif" style="margin-left: 30px;" />
						</a-col>
					</a-col>
                </a-row>
                
                    
            </a-config-provider>
        </div>
        <div class="modal-footer">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { OptionListServiceProxy, OptionListEditDto } from '../../../../shared/service-proxies';
	import 'viewerjs/dist/viewer.css'
	import Vuer from 'v-viewer'
	import Vue from 'vue'
	Vue.use(Vuer, {
		name: 'vuer'
	})
	
    let _this;
    export default {
        name: 'create-or-update',
        mixins: [ModalComponentBase],
        components: {

        },
        data() {
            return {
                zh_CN,
                isLoading:false,
                id: undefined,
                isEdit: false,//是否是编辑
                entity: new OptionListEditDto(),
                isclick: true,
            }
        },
        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.OptionListServiceProxy = new OptionListServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if (this.id) {
                this.getData();
            }else {
				this.entity.displayForm = '1';
			}
        },
        methods: {
            getData() {
                this.isLoading = true;
                this.OptionListServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
                })
            },
			show(item) {
				const vuer = this.$el.querySelector('.images').$vuer
				vuer.show()
			},
            save() {
                if (!this.entity.name) {
                    abp.message.info(this.l('level_name_tip'));
                    return;
                }
                // if (!this.entity.displayForm) {
                //     abp.message.info('DisplayForm_cannot_be_empty');
                //     return;
                // }
                if (this.isclick) {
                    this.isclick = false;
                    this.OptionListServiceProxy.createOrUpdate(
                        this.entity
                    ).finally(() => {
                        this.close()
                    }).then(res => {
                        this.isclick = true;
                        this.success(true)
                    })
                }
            },
        },
    }
</script>
<style scoped>

</style>