<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-row>
                <a-col :span="8">
                    <a-card>
                        <a-row style="margin-bottom: 20px;">
                            <a-col :span="16">
                                <a-button type="primary" @click="createorupdate('create')"
                                    v-if="isGranted('option_list_create')">
                                    <a-icon type="plus" />
                                    {{l('Create')}}
                                </a-button>
                                <!-- <a-button type="primary" @click="createOrEditMachineSupplier('edit')">
                                    <a-icon type="edit"/>
                                    修改
                                </a-button> -->
                                <!-- <a-button type="danger" @click="batchdelete(null)"
                                    v-if="isGranted('option_list_delete')">
                                    <a-icon type="delete" />
                                    {{l('Delete')}}
                                </a-button> -->
                            </a-col>

                            <a-col :span="8" class="d-flex justify-content-end">
                                <a-input-search :placeholder="l('please_enter_a_name_to_search')"
                                    v-model.trim="filterText" enterButton style="width: 200px;"
                                    @search="filteronchange"></a-input-search>
                            </a-col>
                        </a-row>
                        <a-spin :spinning="isLoading">
                            <a-table :columns="columns" :data-source="tableData" :customRow="customRow"
                                :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll }"
                                :rowKey="(record)=>record.id" :scroll="{x:500,y:600}" :pagination="false" size="small">
                                <span slot="displayForm" slot-scope="text,record">
                                    <span v-if="text == '1'">{{l('PullDown')}}</span>
                                    <span v-if="text == '2'">{{l('Tiled')}}</span>
                                </span>
                                <span slot="isQuickCheck" slot-scope="text,record">
                                    <span v-if="text" style="color:#1890ff;"><a-icon type="check" /></span>
                                    <span v-if="!text"><a-icon type="close" /></span>
                                </span>
                                <span slot="action" slot-scope="text,record">
                                    <a @click.stop="createorupdate('update',record)"
                                        v-if="isGranted('option_list_update')">
                                        <a-icon type="edit" />{{l('Edit')}}
                                    </a>
                                    <font v-if="isGranted('option_list_delete')&&isGranted('option_list_delete')">
                                        | </font>
                                    <a @click.stop="batchdelete(record)" v-if="isGranted('option_list_delete')">
                                        <a-icon type="delete" />{{l('Delete')}}
                                    </a>
                                </span>
                            </a-table>
                        </a-spin>
                        <a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
                            :show-total="total => `共有 ${totalItems} 条`" @change="onChangePage"
                            @showSizeChange="showSizeChange" />
                    </a-card>
                </a-col>
                <a-col :span="16">
                    <a-card style="margin-left:5px ;">
                        <a-row style="margin-bottom: 20px;">
                            <a-col :span="16">
                                <a-button type="primary" @click="createorupdatedetail('create')"
                                    v-if="isGranted('option_list_detail_create')">
                                    <a-icon type="plus" />
                                    {{l('Create')}}
                                </a-button>
                                <!-- <a-button type="primary" @click="createOrEditMachineSupplier('edit')">
                                    <a-icon type="edit"/>
                                    修改
                                </a-button> -->
                                <a-button type="danger" @click="batchdeleteDetail(null)"
                                    v-if="isGranted('option_list_detail_delete')">
                                    <a-icon type="delete" />
                                    {{l('Delete')}}
                                </a-button>
                            </a-col>

                            <a-col :span="8" class="d-flex justify-content-end">
                                <a-input-search :placeholder="l('please_enter_a_name_to_search')"
                                    v-model.trim="filterTextDetail" enterButton style="width: 200px;"
                                    @search="filteronchangeDetail"></a-input-search>
                            </a-col>
                        </a-row>
                        <a-spin :spinning="isLoadingDetail">
                            <a-table :columns="columnsDetail" :data-source="tableDataDetail"
                                :customRow="customRowDetail"
                                :row-selection="{selectedRowKeys:selectedRowKeyDetail,onSelect:onSelectDetail,onSelectAll:SelectAllDetail }"
                                :rowKey="(record)=>record.id" :scroll="{x:500,y:600}" :pagination="false" size="small">
                                <span slot="isQualified" slot-scope="text,record">
                                    <span v-if="text" style="color:#1890ff;"><a-icon type="check" /></span>
                                    <span v-if="!text"><a-icon type="close" /></span>
                                </span>
								<span slot="foreColor" slot-scope="text,record" style="text-align: center">
								    <div style="color: transparent" v-if="record.foreColor"
								         :style="{backgroundColor:record.foreColor}">{{record.foreColor}}</div>
									<div style="color: transparent;background-color: #000000;" v-else>#000000</div>
								</span>
                                <span slot="backColor" slot-scope="text,record" style="text-align: center">
                                    <div style="color: transparent" v-if="record.backColor"
                                         :style="{backgroundColor:record.backColor}">{{record.backColor}}</div>
									<div style="color: transparent;background-color: #dfdfdf;" v-else>#dfdfdf</div>
                                </span>
                                <span slot="action" slot-scope="text,record">
                                    <a @click.stop="createorupdatedetail('update',record)"
                                        v-if="isGranted('option_list_detail_update')">
                                        <a-icon type="edit" />{{l('Edit')}}
                                    </a>
                                    <font v-if="isGranted('option_list_detail_delete')">
                                        | </font>
                                    <a @click.stop="batchdeleteDetail(record)"
                                        v-if="isGranted('option_list_detail_delete')">
                                        <a-icon type="delete" />{{l('Delete')}}
                                    </a>
                                </span>
                            </a-table>
                        </a-spin>
                        <a-pagination class="pagination" :total="totalItemsDetail" showSizeChanger showQuickJumper
                            :show-total="total => `共有 ${totalItemsDetail} 条`" @change="onChangePageDetail"
                            @showSizeChange="showSizeChangeDetail" />
                    </a-card>
                </a-col>
            </a-row>
        </a-config-provider>
    </div>
</template>

<script>
    import { AppComponentBase } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        OptionListServiceProxy
    } from '../../../shared/service-proxies';
    import {
        ModalHelper
    } from '@/shared/helpers';
    import createorupdate from "./create-or-update/create-or-update.vue"
    import createorupdatedetail from "./create-or-update-detail/create-or-update-detail.vue"
    let _this;
    export default {
        name: 'option-list',
        mixins: [AppComponentBase],
        components: {
            createorupdate,
            createorupdatedetail
        },
        data() {
            return {
                zh_CN,
                tableData: [],
                filterText: undefined,
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {
                    maxResultCount: 10,
                    skipCount: 0
                },
                isEdit: false,
                isLook: false,
                columns: [
                    {
                        title: this.l('Name'),
                        width: 100,
                        dataIndex: 'name',
                        align: 'left'
                    },
                    {
                        title: this.l('DisplayForm'),
                        width: 100,
                        dataIndex: 'displayForm',
                        align: 'center',
                        scopedSlots: {
                            customRender: "displayForm"
                        },
                    },
                    {
                        title: this.l('IsQuickCheck'),
                        width: 80,
                        dataIndex: 'isQuickCheck',
                        align: 'center',
                        scopedSlots: {
                            customRender: "isQuickCheck"
                        },
                    },
                    {
                        title: this.l('Actions'),
                        width: 120,
                        dataIndex: 'action',
                        align: 'center',
                        scopedSlots: {
                            customRender: "action"
                        },
                        fixed: 'right',
                    },
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRow: [], //选择的整行数据
                isLoading: false, //加载中


                //子项
                optionListId: undefined,
                tableDataDetail: [],
                filterTextDetail: undefined,
                totalItemsDetail: 0, //总数
                // 当前页码
                pageNumberDetail: 1,
                // 共多少页
                totalPagesDetail: 1,
                // 条数显示范围
                pagerangeDetail: [1, 1],
                // 显示条数
                pageSizeOptionsDetail: ["10", "20", "30", "40"],
                requestDetail: {
                    maxResultCountDetail: 10,
                    skipCountDetail: 0
                },
                isEditDetail: false,
                isLookDetail: false,
                columnsDetail: [{
                    title: this.l('Name'),
                    width: 150,
                    dataIndex: 'name',
                    align: 'left'
                },
                {
                    title: this.l('OptionListDetail.IsQualified'),
                    width: 150,
                    dataIndex: 'isQualified',
                    align: 'center',
                    scopedSlots: {
                        customRender: "isQualified"
                    },
                },
                {
                    title: this.l('OptionListDetail.Score'),
                    width: 150,
                    dataIndex: 'score',
                    align: 'center'
                },
				{
				    title: this.l('issueprogress.forecolor'),
				    width: 150,
				    dataIndex: 'foreColor',
				    align: 'center',
				    scopedSlots: {
				        customRender: "foreColor"
				    },
				},
                {
                    title: this.l('issueprogress.backcolor'),
                    width: 150,
                    dataIndex: 'backColor',
                    align: 'center',
                    scopedSlots: {
                        customRender: "backColor"
                    },
                },
                {
                    title: this.l('Actions'),
                    width: 150,
                    dataIndex: 'action',
                    align: 'center',
                    scopedSlots: {
                        customRender: "action"
                    },
                    fixed: 'right',
                },
                ],
                selectedRowKeyDetail: [], // 选择的数据Id
                selectedRowDetail: [], //选择的整行数据
                isLoadingDetail: false, //加载中
            }
        },
        created() {
            _this = this;
            this.OptionListServiceProxy = new OptionListServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        methods: {
            //获取数据
            getData() {
                this.selectedRowKey = [];
                this.optionListId = undefined;
                this.tableDataDetail = [];
                this.isLoading = true;
                this.OptionListServiceProxy.getPaged(
                    this.filterText ? this.filterText : undefined,
                    this.Sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
					this.selectedRowKey[0] = this.tableData[0].id;
					this.selectedRow = this.tableData[0];
					this.optionListId = this.tableData[0].id;
					this.getDataDetail();
                })
            },
            //新增修改
            createorupdate(value, record) {
                if (value == "create") {
                    ModalHelper.create(createorupdate, {
                        isEdit: false,
                        id: undefined
                    }, {
                        width: 550
                    }).subscribe(res => {
                        console.log(res)
                        if (res) {
                            this.getData();
                        }
                        this.selectedRowKey = [];
                    })
                }
                else if (value == "update") {
                    ModalHelper.create(createorupdate, {
                        isEdit: true,
                        id: record.id
                    }, {
                        width: 550
                    }).subscribe(res => {
                        console.log(res)
                        if (res) {
                            this.getData();
                        }
                        this.selectedRowKey = [];
                    })
                }

            },
            //批量删除事件
            batchdelete(record) {
                if (record != null) {
                    this.selectedRowKey.push(record.id);
                }
                if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                this.$confirm({
                    title: this.l("ConfirmInformation"),
                    content: this.l("the_selected_option"),
                    cancelText: this.l('Cancel'),
                    okText: this.l('Ok'),
                    onOk: () => {
                        this.OptionListServiceProxy.batchDelete(this.selectedRowKey).then(res => {
                            this.getData();
                            this.$notification["success"]({
                                message: this.l("SuccessfullyDeleted"),
                            });
                        })
                    }
                })
            },

            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKey.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKey = [...this.selectedRowKey, item.id];
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKey = [];
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                //debugger;
                console.log(record);
                record.checked = true;
                if (record.checked) {
                    this.selectedRowKey[0] = record.id;
                    this.selectedRow = record;
                } else {
                    this.selectedRowKey = [];
                    this.selectedRow = [];
                }
                this.optionListId = record.id;
                this.getDataDetail();
            },
            //搜索框点击事件
            filteronchange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },

            //分页
            onChangePage(page, pageSize) {
                this.selectedRowKey = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKey = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
                this.getData();
            },

            //获取数据
            getDataDetail() {
                this.selectedRowKeyDetail = [];
                this.isLoadingDetail = true;
                this.OptionListServiceProxy.getPaged_Detail(
                    this.optionListId,
                    this.filterTextDetail ? this.filterTextDetail : undefined,
                    this.SortingDetail,
                    this.requestDetail.maxResultCountDetail,
                    this.requestDetail.skipCountDetail
                ).finally(() => {
                    this.isLoadingDetail = false;
                }).then(res => {
                    console.log(res);
                    this.tableDataDetail = res.items;
                    this.totalItemsDetail = res.totalCount;
                    this.totalPagesDetail = Math.ceil(
                        res.totalCount / this.requestDetail.maxResultCountDetail
                    );
                    this.pagerangeDetail = [
                        (this.pageNumberDetail - 1) * this.requestDetail.maxResultCountDetail + 1,
                        this.pageNumberDetail * this.requestDetail.maxResultCountDetail,
                    ];
                })
            },
            //新增修改
            createorupdatedetail(value, record) {
                if (!this.optionListId) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                if (value == "create") {
                    ModalHelper.create(createorupdatedetail, {
                        isEdit: false,
                        id: undefined,
                        optionListId: this.optionListId
                    }, {
                        width: 400
                    }).subscribe(res => {
                        console.log(res)
                        if (res) {
                            this.getDataDetail();
                        }
                        this.selectedRowKeyDetail = [];
                    })
                }
                else if (value == "update") {
                    ModalHelper.create(createorupdatedetail, {
                        isEdit: true,
                        id: record.id,
                        optionListId: this.optionListId
                    }, {
                        width: 400
                    }).subscribe(res => {
                        console.log(res)
                        if (res) {
                            this.getDataDetail();
                        }
                        this.selectedRowKeyDetail = [];
                    })
                }

            },
            //批量删除事件
            batchdeleteDetail(record) {
                if (record != null) {
                    this.selectedRowKeyDetail.push(record.id);
                }
                if (this.selectedRowKeyDetail[0] <= 0 || this.selectedRowKeyDetail[0] == null) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                this.$confirm({
                    title: this.l("ConfirmInformation"),
                    content: this.l("the_selected_option"),
                    cancelText: this.l('Cancel'),
                    okText: this.l('Ok'),
                    onOk: () => {
                        this.OptionListServiceProxy.batchDelete_Detail(this.selectedRowKeyDetail).then(res => {
                            this.getDataDetail();
                            this.$notification["success"]({
                                message: this.l("SuccessfullyDeleted"),
                            });
                        })
                    }
                })
            },

            //行选择
            customRowDetail: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelectDetail(record)
                        }
                    }
                }

            },
            //多选
            SelectAllDetail(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableDataDetail.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKeyDetail.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKeyDetail = [...this.selectedRowKeyDetail, item.id];
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableDataDetail.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKeyDetail = [];
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelectDetail(record) {
                //debugger;
                console.log(record);
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKeyDetail.push(record.id);
                } else {
                    this.selectedRowKeyDetail = _.difference(_this.selectedRowKeyDetail, [record.id]);
                }
            },
            //搜索框点击事件
            filteronchangeDetail() {
                this.pageNumberDetail = 1;
                this.requestDetail.skipCountDetail = 0;
                this.getDataDetail();
            },

            //分页
            onChangePageDetail(page, pageSize) {
                this.selectedRowKeyDetail = [];
                this.pageNumberDetail = page;
                this.requestDetail.skipCountDetail = (page - 1) * this.requestDetail.maxResultCountDetail;
                this.getDataDetail();
            },
            //正常分页展示形式，显示（共有 {0} 条）
            // showTotalFun() {
            // 	console.log(this.totalItems);
            //     return this.l(
            //         "GridFooterDisplayText",
            //         this.totalItems
            //     );
            // },
            showSizeChangeDetail(current, size) {
                this.selectedRowKeyDetail = [];
                this.pageNumber = 1;
                this.requestDetail.maxResultCountDetail = size;
                this.requestDetail.skipCountDetail = (this.pageNumberDetail) * this.requestDetail.maxResultCountDetail - size;
                this.getDataDetail();
            },


        },
    }
</script>
<style scoped>

</style>